import React from "react";
//import Link from "gatsby-link";
import $ from "jquery/dist/jquery";
import consoleStyles from "../styles/components/console-simulator.module.scss";

class consoleSimulator extends React.Component {

    constructor(props) {
        super(props);
            this.state = {
        };
    }

    componentDidMount() {

        // =====================
        // Create required vars
        // =====================
        var output = $(`.${consoleStyles.output}`);
        var input = $(`textarea#input`);
    
        // Creates the event listner for the comands ==
        // Yes this is a long one - could do with some
        // improvements ===============================
        input.keypress(function(e) {
            if (e.which === 13) {
                var inputVal = $.trim(input.val());
                //console.log(inputVal);

                if (inputVal === "help") {
                    help();
                    input.val('');
                } else if (inputVal ==="ping") {
                    pong();
                    input.val('');
                } else if (inputVal === "about") {
                    aboutMe();
                    input.val('');
                } else if (inputVal === "contact") {
                    contactMe();
                    input.val('');
                } else if (inputVal === "clear") {
                    clearConsole();
                    input.val('');
                } else if (inputVal.startsWith("say") === true) {
                    sayThis(inputVal);
                    input.val('');
                } else if (inputVal.startsWith("sudo") === true) {
                    sudo(inputVal);
                    input.val('');
                } else if (inputVal === "time") {
                    getTime();
                    input.val('');
                } else if (inputVal === 'whats that sound' || inputVal === 'what\'s that sound' || inputVal === 'whats that sound?') {
                    seperator();
                    Output('<span class="blue">' + inputVal + '</span></br><span class="red">Machine Broken!</span></br>');
                    seperator();
                    input.val('');
                } else if (inputVal.startsWith("exit") === true) {
                    Output('<span class="blue">Goodbye! Comeback soon.</span>');
                    setTimeout(function() {
                        window.open('https://codepen.io/MarioDesigns');
                    }, 1000);
                } else {
                    Output('<span>command not found</span></br>');
                    input.val('');
                }
            }
        });

        // functions related to the commands typed
        // =======================================

        // prints out a seperator
        function seperator() {
            Output('<span class="seperator">== == == == == == == == == == == == == == == == == ==</span></br>');
        }

        //clears the screen
        function clearConsole() {
            output.html("");
            Output('<span>cleared!</span></br>');
        }

        // prints out a list of "all" comands available
        function help() {
            var commandsArray = ['Help: List of available commands', '>help', '>about', '>contact', '>ping', '>time', '>clear', '>say'];
            for (var i = 0; i < commandsArray.length; i++) {
                var out = '<span>' + commandsArray[i] + '</span><br/>'
                Output(out);
            }
        }

        // prints the result for the pong command
        function pong() {
            Output(`<span>pong</span></br><span class="${consoleStyles.pong}"><b class="${consoleStyles.left}">|</b><b class="${consoleStyles.right}">|</b></span></br>`);
        }

        // function to the say command
        function sayThis(data) {
            data = data.substr(data.indexOf(' ') + 1);
            Output('<span class="green">[say]:</span><span>' + data + '</span></br>');
        }

        // sudo?!? not really
        function sudo(data) {
            data = data.substr(data.indexOf(' ') + 1);
            if (data.startsWith("say") === true) {
                data = "Not gona " + data + " to you, you don't own me!"
            } else if (data.startsWith("apt-get") === true) {
                data = "<span class='green'>Updating...</span> The cake is a lie! There is nothing to update..."
            } else {
                data = "The force is week within you, my master you not be!"
            }
            Output('<span>' + data + '</span></br>');
        }

        // function to get current time...not
        function getTime() {
            Output(`<span>It's the 21st century man! Get a SmartWatch</span></br>`);
        }

        function aboutMe() {
            var aboutMeArray = [
                `>About:`, 
                `Hi There!`, 
                `I am a Senior developer / Tech Lead
                Living in London, United Kingdom.<br/>
                I ❤️ create 🐛 and turn them into 🦋 with JavaScript!`, 
                `Fell free to learn more about me <a href="/about">here</a>.`
            ];
            seperator();
            for (var i = 0; i < aboutMeArray.length; i++) {
                var out = '<span>' + aboutMeArray[i] + '</span><br/>'
                Output(out);
            }
            seperator();
        }

        function contactMe() {
            var contactArray = [
                `>Contact:`, 
                `Feel free to contact me via the following platforms:`, 
                `[GitHub](<a href="https://github.com/Mario-Duarte" rel="noopener noreferrer" target="_blank">https://github.com/Mario-Duarte)</a>`, 
                `[LinkedIn](<a href="https://www.linkedin.com/in/mario-duarte-developer/" rel="noopener noreferrer" target="_blank">https://www.linkedin.com/in/mario-duarte-developer/</a>)`, 
                `[CodePen](<a href="https://codepen.io/MarioDesigns/" rel="noopener noreferrer" target="_blank">https://codepen.io/MarioDesigns/</a>)`, 
                `[Twitter](<a href="https://twitter.com/MDesignsuk" rel="noopener noreferrer" target="_blank">https://twitter.com/MDesignsuk</a>)`,
                `[Behance](<a href="https://www.behance.net/mdesignsuk" rel="noopener noreferrer" target="_blank">https://www.behance.net/mdesignsuk</a>)`,
                `[Dribbble](<a href="https://dribbble.com/MDesignsuk" rel="noopener noreferrer" target="_blank">https://dribbble.com/MDesignsuk</a>)`,
                `[Instagram](<a href="https://www.instagram.com/m.duarte_/" rel="noopener noreferrer" target="_blank">https://www.instagram.com/m.duarte_/</a>)`
            ];
            seperator();
            for (var i = 0; i < contactArray.length; i++) {
                var out = '<span>' + contactArray[i] + '</span><br/>'
                Output(out);
            }
            seperator();
        }

        // Prints out the result of the command into the output div
        function Output(data) {
            $(data).appendTo(output);
        }

    }

    render() {
        return (
            <section className={consoleStyles.console}>
                <div className={consoleStyles.output}>
                    <span>Initializing your console...</span><br/>
                    <span className={consoleStyles.green}>0.0002ms ok!</span><br/>
                    <span className={consoleStyles.seperator}>== == == == == == == == == == == == == == == == == ==</span><br/>
                    <span>Hope you have fun discovering all the <span className={consoleStyles.red}>hidden gems</span>!</span>
                    <br/>
                    <span className={consoleStyles.blue}>type '<span className={consoleStyles.grey}>help</span>' to see a list of commands available <br/> or '<span className={consoleStyles.grey}>contact</span>' for a list of ways to contact me.</span><br/><span className={consoleStyles.blue}>You can view this console simulator on CodePen <a href="https://codepen.io/MarioDesigns/pen/JbOyqe" rel="noopener noreferrer" target="_blank">here</a>.</span><br/>
                    <span className={consoleStyles.seperator}>== == == == == == == == == == == == == == == == == ==</span><br/>
                </div>
                <div className={consoleStyles.action}>
                    <span>dev$ </span>
                    <textarea id="input" className={consoleStyles.input} name="input" cols="30" rows="1"></textarea>
                </div>
            </section>
        )
    }

}

export default consoleSimulator;